import { ToggleGroup, ToggleItem } from '@finn/design-system/atoms/toggle';
import { VEHICLE_VAT } from '@finn/ua-constants';
import {
  Features,
  useDownPayment,
  useIsABVariant,
} from '@finn/ua-featureflags';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { useVoucherStore } from '@finn/ua-vehicle';
import { InfoToolTip } from '@finn/ui-components';
import { cn, getFormattedPrice, trackEvent, useIntl } from '@finn/ui-utils';
import { useEffect, useState } from 'react';

import { getForBusinessCookie } from '../../../helpers';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { useConfigureStore } from '../../pdp-management';
import { calculateTotalPrice } from '../../product-pricing';

const onTooltipOpenChange = (opened: boolean) => {
  if (opened) {
    interactionTrackingEvent(TrackingEventName.TOOLTIP_HOVERED, {
      name: 'downpayment tooltip',
    });
  }
};

export const removeGermanTax = (price: number) => price / VEHICLE_VAT;

export const DownPayment = () => {
  const i18n = useIntl();
  const { isDownPaymentEnabled } = useDownPayment();
  const [isForBusiness, setIsForBusiness] = useState(false);
  const { setDownPayment, downPayment, vehicle, term } = useConfigureStore();

  const voucherData = useVoucherStore((state) => state.referralVoucherData);
  const hasRelativeVoucher = voucherData?.value_rel > 0;

  useEffect(() => {
    const isForBusinessCookie = getForBusinessCookie();
    setIsForBusiness(isForBusinessCookie);
  }, []);
  const { priceType, forceRegularPrice } = useDiscoveryConfig();

  const downPaymentAmountFixed = forceRegularPrice
    ? vehicle?.downpayment_prices?.fixed_amount / 100
    : vehicle?.downpayment_prices?.downpayment_fixed_amount;

  const downPaymentAmount =
    isForBusiness || forceRegularPrice
      ? Math.round(removeGermanTax(downPaymentAmountFixed))
      : downPaymentAmountFixed;

  const oneTimePrice = getFormattedPrice(i18n, downPaymentAmount);
  const price = calculateTotalPrice({
    term,
    vehicle,
    forceRegularPrice,
    priceType,
  });
  const downpaymentPrice = calculateTotalPrice({
    term,
    vehicle,
    isDownPayment: true,
    downPaymentAmount,
    forceRegularPrice,
    priceType,
  });
  const isDownpaymentLabelExp = useIsABVariant(Features.ExpDownpaymentLabel);

  const downpaymentDiscount = price - downpaymentPrice;

  if (!vehicle) return null;

  if (!isDownPaymentEnabled) return null;

  const downpaymentToggleDescription = hasRelativeVoucher
    ? ''
    : i18n.formatMessage('pdp.downPayment.optionDescriptionWith');
  const regularToggleDescription = `+ ${getFormattedPrice(i18n, downpaymentDiscount, 0)}`;

  let labels = {
    title: i18n.formatMessage('pdp.downPayment.title'),
    optionWithDownpayment: i18n.formatMessage(
      'pdp.downPayment.optionTitleWith'
    ),
    optionWithoutDownpayment: i18n.formatMessage(
      'pdp.downPayment.optionTitleWithout'
    ),
    oneTimeFee: i18n.formatMessage('pdp.downPayment.oneTimePrice'),
    tooltip: i18n.formatMessage(`pdp.downPayment.tooltip`),
  };

  if (isDownpaymentLabelExp) {
    labels = {
      title: 'Servicepauschale',
      optionWithDownpayment: 'Einmalige',
      optionWithoutDownpayment: 'Monatliche',
      oneTimeFee: 'Höhe der Servicepauschale',
      tooltip:
        'Die Servicepauschale deckt alles ab, was benötigt wird, um dein Fahrzeug fahrbereit zu machen und dein Abonnement zu starten. Du kannst die Gebühr entweder einmalig bei der Fahrzeugübergabe oder in monatlichen Raten bezahlen.',
    };
  }

  return (
    <div>
      <div className={'mb-4 flex flex-row items-center gap-2'}>
        <h6 className="global-t6-semibold">{labels.title}</h6>

        <InfoToolTip
          onOpenChange={onTooltipOpenChange}
          content={labels.tooltip}
        />
      </div>

      <ToggleGroup
        className="mt-1"
        cols={2}
        defaultValue={downPayment === true ? 'downpayment' : 'regular'}
        value={downPayment === true ? 'downpayment' : 'regular'}
        onValueChange={(value) => {
          if (value) {
            const withDownpayment = value === 'downpayment';

            trackEvent(`DownPayment Selected`, {
              downPayment: withDownpayment,
            });
            setDownPayment(withDownpayment);
          }
        }}
      >
        {['downpayment', 'regular'].map((value) => (
          <ToggleItem
            key={value}
            variant="regular"
            title={
              value === 'downpayment'
                ? labels.optionWithDownpayment
                : labels.optionWithoutDownpayment
            }
            value={value}
            description={
              value === 'downpayment'
                ? downpaymentToggleDescription
                : regularToggleDescription
            }
            withBadge={value === 'downpayment'}
          />
        ))}
      </ToggleGroup>
      <div
        className={cn(
          'flex h-0 items-center justify-between overflow-hidden opacity-0 transition-all duration-300',
          {
            'h-12 opacity-100': downPayment,
          }
        )}
      >
        <div className="body-14-semibold">{labels.oneTimeFee}</div>
        <div className="body-14-regular">{oneTimePrice}</div>
      </div>
    </div>
  );
};
